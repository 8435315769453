import React from 'react';
import { GoogleLogout } from 'react-google-login';
import config from '../config/config.js';

const clientId = config.googleClientId;

function Logout(props) {

  return (
    <div>
      <GoogleLogout
        clientId={clientId}
        buttonText="Logout"
        onLogoutSuccess={props.onSuccess}
      ></GoogleLogout>
    </div>
  );
}

export default Logout;