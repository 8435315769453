
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import config from '../config/config.js';


class MCAPIPage extends React.Component {
    constructor(props) {
        super(props);
        this.user = props.user;
        this.state = {
            name: '',
            client_id: '',
            client_secret: '',
            auth_uri: '',
            rest_uri: '',
            soap_uri: '',
            validated: false,
            message: null,
            messageType: null,
            loading: false
        };

        this.onHandleChange = this.onHandleChange.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
        this.resetMessage = this.resetMessage.bind(this);
        this.copyURI = this.copyURI.bind(this);
    }

    onHandleChange(event) {
        this.setState({[event.target.id]: event.target.value});
    }


    resetMessage() {
        this.setState({message: null, messageType: null});
    }

    copyURI() {
        this.setState({
            rest_uri: this.state.auth_uri.replace('auth', 'rest'),
            soap_uri: this.state.auth_uri.replace('auth', 'soap')
        });
    }

    onHandleSubmit(event) {
        this.resetMessage();
        event.preventDefault();
        event.stopPropagation();
        const form = document.getElementById('mcAPIFrm');
       
        if (form.checkValidity() === false) {
            this.setState({validated: true});
            return;
        }


        this.setState({validated: true, loading: true}, async () => {
            const apiResponse = await fetch(`${config.apiEndpoint}/api/v1/mcapi`, {
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify(this.state),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.user.token}`
                    }
                });
            this.setState({loading: false});
            const response = await apiResponse.json();
            if (response.error) {
                this.setState({message: response.error, messageType: 'danger'});
            }
            else if (response.message) {
                // this succeeded, so we reset the state/form
                this.setState({
                    name: '',
                    client_id: '',
                    client_secret: '',
                    auth_uri: '',
                    rest_uri: '',
                    soap_uri: '',
                    validated: false,
                });
                this.setState({message: response.message, messageType: 'success'});
            }
        });

    }
    
    render() {
        return (
            <Form id="mcAPIFrm" noValidate validated={this.state.validated} onSubmit={this.onHandleSubmit}>
                <Alert variant={this.state.messageType} show={this.state.message} onClose={this.resetMessage} dismissible>
                    {this.state.message}
                </Alert>
                <Form.Group controlId="name">
                    <Form.Label>Client Name</Form.Label>
                    <Form.Control 
                        required
                        type="text" 
                        value={this.state.name}
                        onChange={this.onHandleChange}
                        placeholder="Offprem" />
                    <Form.Control.Feedback type="invalid">A unique name is required.</Form.Control.Feedback>
                </Form.Group>
    
                <Form.Group controlId="client_id">
                    <Form.Label>Client ID</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={this.state.client_id}
                        onChange={this.onHandleChange}
                        placeholder="" />
                    <Form.Control.Feedback type="invalid">Client ID is required</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="client_secret">
                    <Form.Label>Client Secret</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.client_secret}
                        onChange={this.onHandleChange}
                        placeholder="" />
                </Form.Group>
    
                <Form.Row>
                    <Form.Group as={Col} controlId="auth_uri">
                        <Form.Label>Auth base URI</Form.Label>
                        <Form.Control 
                            required
                            type="text" 
                            value={this.state.auth_uri}
                            onChange={this.onHandleChange}
                            placeholder="https://mc688cw5yd19g9q-bgsfwkhwvzv4.auth.marketingcloudapis.com/" />
                        <Form.Control.Feedback type="invalid">An auth URI is required</Form.Control.Feedback>
                    </Form.Group>
                    <Button id="copyBtn" variant="dark" onClick={this.copyURI}>
                        Autofill
                    </Button>
                </Form.Row>

                <Form.Group controlId="rest_uri">
                    <Form.Label>REST URI</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.rest_uri}
                        onChange={this.onHandleChange}
                        placeholder="" />
                </Form.Group>

                <Form.Group controlId="soap_uri">
                    <Form.Label>SOAP URI</Form.Label>
                    <Form.Control
                        type="text"
                        value={this.state.soap_uri}
                        onChange={this.onHandleChange}
                        placeholder="" />
                </Form.Group>
    
                <Button 
                    id="submitBtn"
                    variant="primary"
                    onClick={this.onHandleSubmit}
                    type="submit"
                    disabled={this.state.loading}
                >
                    {this.state.loading ? 
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    : ' ' }
                    &nbsp;Save
                </Button>
            </Form>
        )
    }
    
}

export default MCAPIPage;