
import Jumbotron from 'react-bootstrap/Jumbotron';
import GoogleLoginButton from '../components/GoogleLoginButton';

const LoginPage = (props) => (
    <Jumbotron>
        <h1>Login</h1>
        <p>
        You must login for access.  Login using your Offprem Google account.
        </p>
        <GoogleLoginButton onSuccess={props.onSuccess} onFailure={props.onFailure} />
  </Jumbotron>
)

export default LoginPage;