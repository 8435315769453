
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';

const UnauthorizedPage = () => (
    <Jumbotron>
        <h1>Unauthorized</h1>
        <p>
        You've reached this page because you are not signed into an Offprem Google Account.
        </p>
        <p>
        If you need help, <Button variant="primary" onClick={() => {window.location.href = 'mailto:scott.rini@offprem.tech'}}>Contact Us</Button>
        </p>
  </Jumbotron>
)

export default UnauthorizedPage;