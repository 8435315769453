
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';

const AboutPage = () => (
    <Jumbotron>
        <h1>The Offprem Management App</h1>
        <p>
        This is a WIP.  If you have any questions, use the button to contact us.
        </p>
        <p>
        <Button variant="primary" onClick={() => {window.location.href = 'mailto:scott.rini@offprem.tech'}}>Contact Us</Button>
        </p>
  </Jumbotron>
)

export default AboutPage;