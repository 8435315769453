/* eslint-disable import/no-anonymous-default-export */
export default
{
    'googleClientId': '696297201730-m78lmb13sko6bkgso9oj7a9jrcguls0t.apps.googleusercontent.com',
    'mongoUri': 'mongodb://0ffpremDBUs3r2021:*wjLK1I7FS&w@127.0.0.1:27017/offprem?retryWrites=true',
    'apiEndpoint': 'https://manage.offprem.tech:3001',
    'lastpassPass': 'ChevyTreeOffprem123!',
    'sessionSecret': 'ee8bf89b-3cfc-4b4b-a263-0e6b91c6d394',
    'corsOrigin': 'https://manage.offprem.tech'
}
