import './App.css';
import { MemoryRouter, Switch, Route, Redirect } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import React from 'react';

import logo from './logo.svg';

import MCAPIPage from './pages/MCAPI';
import LastPassPage from './pages/LastPass';
import AboutPage from './pages/About';
import UnauthorizedPage from './pages/Unauthorized';
import LoginPage from './pages/Login';

import { LinkContainer } from 'react-router-bootstrap';
import ProtectedRoute from './components/ProtectedRoute';

import GoogleLogoutButton from './components/GoogleLogoutButton';

import config from './config/config.js';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      User: {
        isAuthenticated: false,
        isBlocked: false
      }
    };

    this.signInSuccess = this.signInSuccess.bind(this);
    this.signInFailure = this.signInFailure.bind(this);
    this.signOutSuccess = this.signOutSuccess.bind(this);
    this.refreshTokenSetup = this.refreshTokenSetup.bind(this);
  }

  refreshTokenSetup(res) {
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
  
    const refreshToken = async () => {
      const newAuthRes = await res.reloadAuthResponse();
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
      
      this.state.token.id_token = newAuthRes.id_token;
  
      // Setup the other timer after the first one
      setTimeout(refreshToken, refreshTiming);
    };
  
    // Setup first refresh timer
    setTimeout(refreshToken, refreshTiming);
  }

  checkBlockedUser(obj) {
    let emailTest = /(.*)@offprem.tech$/;

    return !emailTest.test(obj.email);
  }

  async signInSuccess(res) {
    const apiResponse = await fetch(`${config.apiEndpoint}/api/v1/auth`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        token: res.tokenId
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const dataFromDb = await apiResponse.json();

    this.setState({
      User: {
        token: res.tokenId,
        isAuthenticated: true,
        isBlocked: this.checkBlockedUser(res.profileObj),
        ...res.profileObj,
        ...dataFromDb
      },
      token: {
        ...res.tokenObj
      }
    });
    this.refreshTokenSetup(res);
  }

  signOutSuccess(res) {
    this.setState({
      User: {
        isAuthenticated: false,
        isBlocked: false
      }
    });
  }

  signInFailure(x) {
    console.log(`Login failure `, x);
    alert('There was an issue logging in');
  }

  render() {
    return (
      <MemoryRouter>
        {!this.state.User.isAuthenticated ? <Redirect to="/login" /> : ''}
        {this.state.User.isAuthenticated && this.state.User.isBlocked ? <Redirect to="/unauthorized" /> : ''}
        {this.state.User.isAuthenticated ? 
        <Navbar bg="light" expand="lg">
          <LinkContainer to="/login">
            <Navbar.Brand><img height="75px" alt="logo" src={logo} /></Navbar.Brand>
          </LinkContainer>
          
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <LinkContainer to="/lastpass">
                <Nav.Link>LastPass</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/mcapi">
                <Nav.Link>MC API</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link>About</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
          {this.state.User.isDeveloper ? 'Dev ' : ''}
          <img id="profileImage"  alt={this.state.User.givenName} src={this.state.User.imageUrl} />
          <GoogleLogoutButton onSuccess={this.signOutSuccess} />
        </Navbar> : '' }
        <Container className="p-3">
          <Switch>
            <Route exact path="/login">
              {this.state.User.isAuthenticated ? <Redirect to="/lastpass" /> : ''}
              <LoginPage onSuccess={this.signInSuccess} onFailure={this.signInFailure}/>
            </Route>
            <Route exact path="/unauthorized">
              <UnauthorizedPage user={this.state.User} />
            </Route>
            <ProtectedRoute exact path="/lastpass" user={this.state.User} component={LastPassPage} />
            <ProtectedRoute exact path="/mcapi" user={this.state.User} component={MCAPIPage} />
            <ProtectedRoute exact path="/about" user={this.state.User} component={AboutPage} />
           
          </Switch>
        </Container>
      </MemoryRouter>
    )
  }
}

export default App;