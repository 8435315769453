
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import config from '../config/config.js';



class LastPassPage extends React.Component {
    constructor(props) {
        super(props);
        this.user = props.user;
        this.state = {
            name: '',
            url: '',
            type: 'mc',
            username: '',
            password: '',
            notes: '',
            validated: false,
            message: null,
            messageType: null,
            loading: false
        };

        this.onHandleChange = this.onHandleChange.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
        this.resetMessage = this.resetMessage.bind(this);
    }

    onHandleChange(event) {
        this.setState({[event.target.id]: event.target.value});
    }


    resetMessage() {
        this.setState({message: null, messageType: null});
    }

    onHandleSubmit(event) {
        this.resetMessage();
        event.preventDefault();
        event.stopPropagation();
        const form = document.getElementById('lastPassFrm');
       
        if (form.checkValidity() === false) {
            this.setState({validated: true});
            return;
        }
        
        this.setState({validated: true, loading: true}, async () => {
            const apiResponse = await fetch(`${config.apiEndpoint}/api/v1/lastpass`, {
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify(this.state),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.user.token}`
                    }
                });
            this.setState({loading: false});
            const response = await apiResponse.json();
            if (response.error) {
                this.setState({message: response.error, messageType: 'danger'});
            }
            else if (response.message) {
                // this succeeded, so we reset the state/form
                this.setState({
                    name: '',
                    url: '',
                    type: 'mc',
                    username: '',
                    password: '',
                    notes: '',
                    validated: false,
                });
                this.setState({message: response.message, messageType: 'success'});
            }
        }); 

    }
    
    render() {
        return (
            <Form id="lastPassFrm" noValidate validated={this.state.validated} onSubmit={this.onHandleSubmit}>
                <Alert variant={this.state.messageType} show={this.state.message} onClose={this.resetMessage} dismissible>
                    {this.state.message}
                </Alert>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control 
                        required
                        type="text" 
                        value={this.state.name}
                        onChange={this.onHandleChange}
                        placeholder="Offprem Marketing Cloud" />
                    <Form.Control.Feedback type="invalid">A unique name is required.  This should reflect the company name and type of credential.</Form.Control.Feedback>
                </Form.Group>
    
                <Form.Group controlId="url">
                    <Form.Label>URL</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        value={this.state.url}
                        onChange={this.onHandleChange}
                        placeholder="http://mc.exacttarget.com" />
                    <Form.Control.Feedback type="invalid">A URL is required for where these credentials apply.</Form.Control.Feedback>
                </Form.Group>
    
                <Form.Row>
                    <Form.Group as={Col} controlId="type">
                        <Form.Label>Type</Form.Label>
                        <Form.Control 
                            value={this.state.type}
                            onChange={this.onHandleChange} as="select">
                            <option value="mc">Marketing Cloud</option>
                            <option value="sales">Sales/Service Cloud</option>
                            <option value="sftp">(S)FTP Site</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Row>
    
                <Form.Row>
                    <Form.Group as={Col} controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control 
                            required
                            type="text"
                            value={this.state.username}
                            onChange={this.onHandleChange}
                            placeholder="Username" />
                        <Form.Control.Feedback type="invalid">Username is required</Form.Control.Feedback>
                    </Form.Group>
    
                    <Form.Group as={Col} controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            required 
                            value={this.state.password}
                            onChange={this.onHandleChange}
                            type="password" 
                            placeholder="Password" />
                        <Form.Control.Feedback type="invalid">Password is required</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
    
                <Form.Row>
                    <Form.Group controlId="notes">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control value={this.state.notes} onChange={this.onHandleChange} as="textarea" rows={5} cols={200} />
                    </Form.Group>
                </Form.Row>
    
                <Button 
                    id="submitBtn"
                    variant="primary"
                    onClick={this.onHandleSubmit}
                    type="submit"
                    disabled={this.state.loading}
                >
                    {this.state.loading ? 
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    : ' ' }
                    &nbsp;Submit
                </Button>
            </Form>
        )
    }
    
}

export default LastPassPage;