import React from 'react';
import { GoogleLogin } from 'react-google-login';
import config from '../config/config.js';

const clientId = config.googleClientId;

function GoogleLoginButton(props) {

  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        buttonText="Login"
        onSuccess={props.onSuccess}
        onFailure={props.onFailure}
        cookiePolicy={'single_host_origin'}
        style={{ marginTop: '100px' }}
        isSignedIn={true}
      />
    </div>
  );
}

export default GoogleLoginButton;